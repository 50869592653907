<template>
  <div class="userinfo-container">
    <div class="header-title">
      <i class="el-icon-back bach" @click="previous" />
      <span style="vertical-align: middle;">{{ orderInfo.role_name }}</span>
    </div>
    <div class="userinfo-box">
      <Loading :show="loading">
        <div data-spm="userinfo">
          <div class="next-loading next-loading-inline sc-khAkCZ jIEYGu">
            <div class="next-loading-wrap">
              <div class="sc-iBaQBe cGFAXW">
                <div class="sc-eggMyH fLwpMi">
                  <h3>用户基本信息</h3>
                  <div style="margin-left: 16px">
                    <button
                      type="button"
                      class="next-btn next-medium next-btn-primary next-btn-text"
                      style="margin-right: 8px"
                      @click="edit"
                    >
                      <span class="next-btn-helper">编辑基本信息</span>
                    </button>
                  </div>
                </div>
                <div class="next-row next-row-wrap wind-rc-data-fields">
                  <form-label label="角色名称"> {{ orderInfo.role_name ||'-' }} </form-label>
                  <form-label label="RID"> {{ orderInfo.id }} </form-label>
                  <form-label label="创建时间"> {{ orderInfo.create_time }} </form-label>
                  <form-label label="备注"> {{ orderInfo.remark||'-' }} </form-label>
                  <form-label label="域"> {{ orderInfo.domain||'-' }} </form-label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Loading>
    </div>
    <tabs-content ref="tabsContent" @create_init="get_info_user" />

    <el-drawer
      ref="drawer"
      size="30"
      custom-class="drawer-warp"
      title="编辑基本信息"
      :visible.sync="drawer"
    >
      <div class="drawer__content">
        <el-form
          ref="ruleForm"
          label-position="top"
          :model="form"
          :rules="rules"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="角色名称" prop="role_name">
            <el-input v-model="form.role_name" />
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input
              v-model="form.remark"
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 4}"
              placeholder="请输入内容" />
          </el-form-item>
        </el-form>
      </div>
      <div class="panel-footer-drawer custom-panel-footer">
        <div class="btn-con" style="float: left">
          <el-button
            class="next-btn next-medium"
            type="primary"
            @click="submit('ruleForm')"
          >确定</el-button>
          <!-- <button
            type="button"
            class="next-btn next-medium next-btn-primary"
            style="margin-right: 8px"
            @click="submit('ruleForm')"
          >
            <span class="next-btn-helper">确定</span>
          </button> -->
          <button
            type="button"
            class="next-btn next-medium next-btn-normal"
            style="margin-right: 8px"
            @click="$refs.drawer.closeDrawer()"
          >
            <span class="next-btn-helper">取消</span>
          </button>
        </div>
      </div>
    </el-drawer>

  </div>
</template>

<script>
import { get_info, edit_role } from '@/api/roles'
import tabsContent from './tabs_content.vue'

export default {
  components: {
    tabsContent
  },
  data () {
    return {
      drawer: false,
      loading: false,
      activeName: '1',
      orderInfo: {},
      tableData: [],
      form: {},
      rules: {
        role_name: [
          { required: true, message: '不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.get_info_user()
  },

  methods: {
    get_info_user () {
      const id = this.$route.query.id
      this.loading = true
      get_info(id).then(res => {
        this.loading = false
        this.orderInfo = res.data
        console.log(res)
      })
    },
    edit () {
      this.drawer = true
      this.form = JSON.parse(JSON.stringify(this.orderInfo))
    },
    submit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const params = {
            ...this.form,
            role_id: this.form.id
          }
          edit_role(params).then(res => {
            this.drawer = false
            this.get_info_user()
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    previous () {
      this.$router.push('/roles')
    }
  }
}
</script>

<style scoped>
.userinfo-container >>> .el-tabs__item {
  font-weight: 400 !important;
}
.userinfo-box {
  padding-top: 25px;
  color: #333;
}
.next-loading {
  width: 100%;
}
.fLwpMi {
  margin-bottom: 12px;
  white-space: nowrap;
}
.fLwpMi > h3 {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
}
h3,
h4 {
  margin-bottom: 7px !important;
  font-size: 14px;
}
.fLwpMi div:first-of-type {
  display: inline-block;
  margin-left: 40px;
}
.cGFAXW {
  margin-bottom: 20px;
}
.fLwpMi {
  margin-bottom: 12px;
  white-space: nowrap;
}
.eEgMuO {
  display: flex;
  margin-bottom: 8px;
  width: 50%;
  min-width: 300px;
}
.TJhJs {
  display: flex;
  flex-wrap: wrap;
  margin: 0px;
  padding: 0px;
  width: 100%;
}
.eRLVLq {
  margin-right: 16px;
  width: 164px;
}
</style>
